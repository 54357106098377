






















































































































































































import Vue from 'vue';
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import ISkill from '@/models/skills/ISkill';
import ISkillLevel from '@/models/skills/ISkillLevel';
import SkillProvider from '@/api/SkillProvider';
import { SkillType } from '@/models/skills/SkillEnums';
import ITypedMap from '@/models/util/ITypedMap';
import { filters } from '@/filters/Filters';

interface IData {
    skillLevelData: ISkillLevel[];
    fetched: boolean;
}

export default Vue.extend({
    components: {
        SpriteIcon,
    },
    props: {
        "skillData": {
            type: Object as () => ISkill,
        },
        "show": {
            type: Boolean as () => boolean,
        },
    },
    data(): IData {
        return {
            skillLevelData: [],
            fetched: false,
        };
    },
    watch: {
        show(to: boolean, from: boolean) {
            if (to == from) {
                return;
            }

            if (to) {
                if (!this.fetched) {
                    this.fetchData();
                }

                this.$anime({
                    targets: ".skill-stub-tooltip",
                    opacity: "1.0",
                });
            } else {
                this.$anime({
                    targets: ".skill-stub-tooltip",
                    opacity: "0.0",
                });
            }
        },
        skillData() {
            this.fetched = false;
            this.skillLevelData = [];
        }
    },
    computed: {
        SkillType(): any {
            return SkillType;
        },
        firstLevel(): ISkillLevel|null {
            return this.skillLevelData.find((l) => l.level == 1) || this.skillLevelData.length > 0 ? this.skillLevelData[0] : null;
        },
        maxLevel(): ISkillLevel|null {
            return this.skillLevelData.find((l) => l.level == this.skillData.spLevels) || this.firstLevel;
        },
        // targets(): string {
        //     const effects = Object.keys(this.skillData.effects.map((e) => {
        //         let ret: ITypedMap<boolean> = {};
        //         ret[e.effectApplyTypeStr] = true;
        //         return ret;
        //     }).reduce((prev, curr) => Object.assign(prev, curr), {}));

        //     return effects.join(", ");
        // }
    },
    mounted() {
        if (this.show) {
            this.fetchData();
        }
    },
    methods: {
        async fetchData() {
            this.skillLevelData = [];
            if (this.skillData != null && this.skillData.id !== 0) {
                this.skillLevelData.push(...(await SkillProvider.getSkillLevels(this.skillData.id)));
            }

            this.fetched = true;
        },
        skillTypeToString(type: SkillType): string {
            return filters.skillType(type);
        }
    }
});
