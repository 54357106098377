

















import Vue from 'vue';
import SkillStub from "@/components/skill/SkillStub.vue";
import ISkill from '@/models/skills/ISkill';
import SkillProvider from '@/api/SkillProvider';

interface IData {
    linkTarget: string;
}

export default Vue.extend({
    props: {
        "skillId": {
            type: Number as () => number,
        },
        "fill": {
            type: Boolean as () => boolean,
        },
        "skillData": {
            type: Object as () => ISkill,
        },
        "iconOnly": {
            type: Boolean as () => boolean,
        },
        subskills: {
            type: Array as () => number[],
        },
        awakenedSkillId: {
            type: Number as () => number,
        }
    },
    components: {
        SkillStub,
    },
    data(): IData {
        return {
            linkTarget: `${this.skillId || this.skillData.id}`,
        };
    },
    methods: {
        onLoaded(skill: ISkill) {
            this.linkTarget = SkillProvider.skillSlug(skill);
            this.$emit("done", skill);
        }
    }
});
